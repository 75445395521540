import { Route, Routes } from "react-router-dom"
import Login from "../pages/login"
import UploadFile from "../pages/upload-file"

import Dashboard from './../pages/dashboard';
export const Router = () => {
    
    return (
        
        <Routes>
            <Route path="/" element={<Dashboard></Dashboard>}/>
            <Route path="/login" element={<Login />} />
            <Route path="/uploadfile" element={<UploadFile />} />
        </Routes>
    )
}
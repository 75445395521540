import './slider.css';
import { React, useState, useEffect, useRef } from "react";
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';
import { getAllLocationsByMMSI } from '../../httphandler/urlMapper';
import 'leaflet/dist/leaflet.css';
//var slides = document.querySelectorAll('#slides .slide');
const containerStyle = {
    width: '100%',
    height: '60vh'
};
const otherShipsIcon = L.icon({
    iconUrl: require('../../assets/ship_move.ico'),
    iconSize: [50, 50],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
});
const currentShipIcon = L.icon({
    iconUrl: require('../../assets/current_ship.ico'),
    iconSize: [20, 20],
    className: 'blink',
    iconAnchor: ['0.5f','1f'],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
});

const Slider = (props) => {
    useEffect(() => {
    }, [props]);
    const [playing, setPlaying] = useState(true);
    const [slideInterval, setSlideInterval] = useState();
    return (
        <>
            {
                props.cargoLoc ?
                    <>

                        <MapContainer
                            key={JSON.stringify([props.cargoLoc.currentMariner.lat, props.cargoLoc.currentMariner.lon])}
                            center={[props.cargoLoc.currentMariner.lat, props.cargoLoc.currentMariner.lon]}
                            zoom={9}
                            scrollWheelZoom={false}
                            className="map_container"
                            
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker
                                position={[props.cargoLoc.currentMariner.lat, props.cargoLoc.currentMariner.lon]}
                                icon={currentShipIcon}
                            >
                                {/* <Popup>
                                        A pretty CSS3 popup. <br /> Easily customizable.
                                    </Popup> */}
                            </Marker>
                            {
                                props.cargoLoc.otherLatLong.map((item, index) => {
                                    return (
                                        <>
                                            <Marker
                                                key={index}
                                                position={[item.lat, item.lon]}
                                                icon={otherShipsIcon}
                                            >
                                                {/* <Popup>
                                        A pretty CSS3 popup. <br /> Easily customizable.
                                    </Popup> */}
                                            </Marker>
                                        </>
                                    )
                                })
                            }
                        </MapContainer>
                    </> : ""
            }




        </>)
}
export default Slider;

import './login.css';
import logo from "../assets/logo.png";
import { React, useState, useEffect, useRef } from "react";
import {uploadFile} from '../httphandler/urlMapper';
import { useNavigate } from "react-router-dom";
const UploadFile = () => {
    const [file, setFile] = useState();
    const navigate = useNavigate();
    const onSubmit=async ()=>{
        debugger;
        if(!file)
        {
            alert('Please select file to upload.');
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        const result = await uploadFile(formData);
        if(result.statusCode === 200){
            alert('File has been uploaded.');
            navigate('/');
        }else {
            alert('Something went wrong while uploading, please try again.');
        }
    }
    const fileChange = (e) => {
        const file = e.target.files[0];
    
        if(file === undefined) {
            alert('Please select valid file.')
        
            } else if(getExtentions(e.target.value) !== "xlsx") {
            alert('Please select valid extension. (.xlsx)')
    
    
        } else {
            setFile(file);
        }
    }
    const getExtentions=(filename)=>{
        return filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
    }
    return (
        <>
            <div className='loginContent'>
                <div className="screen-1">
                    <div className='logo'>
                        <img src={logo} />
                    </div>
                    <div className="password">
                        <div className="sec-2">
                            <input  className="pas" type="file" name="password" onChange={(e) => fileChange(e)} />
                        </div>
                    </div>
                    <button className="login" onClick={onSubmit}>
                    <i class="bi bi-cloud-arrow-up"></i>   Upload File
                    </button>
                </div>
            </div>
        </>)
}
export default UploadFile;
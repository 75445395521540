
import './login.css';
import logo from "../assets/logo.png";
import { React, useState, useEffect, useRef } from "react";
import {login} from '../httphandler/urlMapper';
import { useNavigate } from "react-router-dom";
const Login = () => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const navigate = useNavigate();
    const onSubmit=async ()=>{
        if(!username)
        {
            alert('Please type username');
            return;
        }
        if(!password)
        {
            alert('Please type password');
            return;
        }
        const result = await login({username,password});
        if(result.statusCode === 200){
            localStorage.setItem('_token',result.data.data.token);
            navigate('/')
        }else {
            alert('Username or password is incorrect.');
        }
    }
    return (
        <>
            <div className='loginContent'>
                <div className="screen-1">
                    <div className='logo'>
                        <img src={logo} />
                    </div>

                    <div className="email">
                        <label for="email">Username</label>
                        <div className="sec-2">

                            <input type="username" name="username" onChange={(e) => setUsername(e.target.value)} />
                        </div>
                    </div>
                    <div className="password">
                        <label for="password">Password</label>
                        <div className="sec-2">
                            <input className="pas" type="password" name="password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                    </div>
                    <button className="login" onClick={onSubmit}>Login </button>
                </div>
            </div>
        </>)
}
export default Login;
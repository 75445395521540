import { doGet, doPost,doPostFile } from "../utils/http";

export const getAllLocations = async () => {
    return doGet('/mariners/getAllMariners')
}
export const getAllLocationsByMMSI = async (mmsi) => {
    return doGet('/mariners/getcargoSatatusByMmsi?mmsi='+mmsi);
}
export const getSheetDataByName = async (name) => {
    return doGet('/mariners/getexeclinfo?name='+name);
}

// login
export const login = async (obj) => {
    return doPost('/login',obj);
}
// upload
export const uploadFile = async (obj) => {
    return doPostFile('/mariners/uploadfile',obj,{},true);
}
import { useEffect, useState } from 'react';
import { getAllLocations, getAllLocationsByMMSI, getSheetDataByName } from '../httphandler/urlMapper';
import Slider from './sub/slider';
import 'font-awesome/css/font-awesome.min.css';
import logo from "./../assets/circel-logo.jpeg";
import moment from 'moment'
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
    const [locationList, setLocationList] = useState([]);
    const [sheetData, setSheetData] = useState([]);
    const [cargoLoc, setCargoCurrentLocation] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPlay, setIsPlay] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        loadAllLocations();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isPlay) { //I used '!paused' because I set pause initially to false. 
                clearInterval(interval);
                return;
            }
            nextShip();
        }, 10000);
        return () => clearInterval(interval);
    });
    const loadAllLocations = async () => {

        let result = await getAllLocations();
        if (result.statusCode === 200) {
            setLocationList(result.data);
            await loadCargoLocationByMMSI(result.data[0]);
        }
    }
    const loadCargoLocationByMMSI = async (mmsi) => {
        const result = await getAllLocationsByMMSI(mmsi);
        setCargoCurrentLocation(result.data);
        await loadExeclSheetData(result.data.currentMariner.name);
    }
    const nextShip = async () => {
        if (locationList.length === (currentIndex + 1)) {
            setCurrentIndex(0);
            await loadCargoLocationByMMSI(locationList[0]);
        } else {
            await loadCargoLocationByMMSI(locationList[(currentIndex + 1)]);
            setCurrentIndex((currentIndex + 1));
        }
    }
    const prevShip = async () => {
        if (currentIndex === 0) {
            setCurrentIndex((locationList.length - 1));
            await loadCargoLocationByMMSI(locationList[locationList.length - 1]);
        } else {
            await loadCargoLocationByMMSI(locationList[(currentIndex - 1)]);
            setCurrentIndex((currentIndex - 1));
        }
    }
    const palyToggle = async () => {
        setIsPlay(!isPlay);

    }
    const loadExeclSheetData = async (name) => {
        const result = await getSheetDataByName(name);
        if (result.statusCode === 200) {
            setSheetData(result.data);
        }
    }
    const onLogout=()=>{
        localStorage.clear();
        navigate('/login');
    }
    return (
        <>
            <div class="header">
                {/* <img width="450" height="100" src={logo} alt='Logo' /> */}
            </div>
            <div class="lg-container">
                <div class="row">
                    <div class="col-lg-10">
                        <div className="main-slider">
                            {
                                cargoLoc ?
                                    <>
                                        <Slider cargoLoc={cargoLoc}></Slider>
                                        <div className="slider-buttons">
                                            <button onClick={async (e) => await prevShip()} className="controls btn btn-success btn-circle btn-xl" id="pause"><i className='fa fa-backward'></i></button>
                                            <button onClick={async (e) => await palyToggle()} className="controls btn btn-success btn-circle btn-xl" id="pause">

                                                {
                                                    !isPlay ? <><i className='fa fa-play'></i></> : <><i className='fa fa-pause'></i></>
                                                }
                                            </button>
                                            <button onClick={async (e) => await nextShip()} className="controls btn btn-success btn-circle btn-xl" id="pause"><i className='fa fa-forward'></i></button>
                                        </div>
                                    </> : ""

                            }

                        </div>

                    </div>
                    {
                        cargoLoc ? <>
                            <div class="col-lg-2">
                                <a style={{float:'right'}} href='#' onClick={onLogout}>Logout</a>
                                 <img width="250" height="200" src={logo} alt='Logo' />
                                {/* <h4>Latest Position</h4>
                                <div class=" line"></div> 
                                <p>Last Position: <span class="black">{cargoLoc.currentMariner.last_position_UTC}</span> </p>
                                */}
                                <p class="red">{cargoLoc.currentMariner.name}</p>
                                {/* <p>Type : <span class="black">{cargoLoc.currentMariner.type}</span> </p>
                                <p>Type Specific : <span class="blue">{cargoLoc.currentMariner.type_specific}</span> </p>
                                <p>Lattitude/Logitude: <span class="blue">{cargoLoc.currentMariner.lat}<span>&#176;</span>/{cargoLoc.currentMariner.lon}<span>&#176;</span></span>
                                </p> */}
                                <p>Destination: <span class="black">{cargoLoc.currentMariner.destination}</span> </p>
                                <p>Speed/Course: <span class="black">{cargoLoc.currentMariner.speed}<span>&#176;</span></span> </p>
                                {/* <p>AIS Source : <span class="blue">1194</span></p> */}
                                <div class="Source">
                                    <p>
                                        {/* <span class="grey">US</span>  */}
                                        <span class="black">{cargoLoc.currentMariner.country_iso}</span>
                                    </p>
                                    <p>
                                        {/* <span class="grey">US</span> */}
                                        {cargoLoc.currentMariner.destination}</p>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <i class="bi bi-circle"></i>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div class="line2">
                                        </div>
                                        <i class="arrow right"></i>
                                    </div>
                                </div>

                                {/* <div class="UTC">
                                    <p class="end"> <span class="black">ATD:</span> 2021-08-21 18:19LT(UTC-8)</p>
                                    <p class="end"> <span class="black">ATD:</span> 2021-08-21 18:19LT(UTC-8)</p>
                                </div> */}
                            </div>
                        </> : ""
                    }

                </div>
                <br></br>
                <div class="row">
                    <div class="col-md-10">
                        <table className='table table-custom'>
                            <thead className='thead-dark'>
                                <tr>

                                    <th>Name</th>
                                    {
                                        sheetData && sheetData.dateRange && sheetData.dateRange.map((item, index) => {
                                            return (<>
                                            {
                                                    index === 2 ? <>
                                                        <th className='header-dark'>
                                                            {moment(item).format('ddd DD MMMM yyyy')}
                                                        </th></> : <> <th>
                                                            {moment(item).format('ddd DD MMMM yyyy')}
                                                        </th></>
                                            }
                                            </>)
                                        })
                                    }
                                </tr>
                            </thead>
                            {
                                sheetData && sheetData.shipInfo && sheetData.shipInfo.map((item, index) => {
                                    return (<>

                                        <tr class="table-row" key={index}>
                                            <td>
                                                {/* <h5 class="table-heading"></h5> */}
                                                <h6 class="table-heading">{item.ShipName}</h6>
                                            </td>
                                            {

                                                item.ShipInfo && item.ShipInfo.length > 0 && item.ShipInfo.map((it, index) => {
                                                    return (
                                                        <>
                                                            <td>
                                                                {it.Data}
                                                            </td>
                                                        </>)
                                                })
                                            }


                                        </tr>

                                    </>)
                                })

                            }



                        </table>
                    </div>
                </div>
            </div>


        </>)
}
export default Dashboard;